import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/core/session/session.service';
import { activeFeatures } from '@monsido/core/constants/general.constant';
import { Domain } from '@monsido/modules/models/api/domain';
import { Page } from '@monsido/ng2/modules/page-details/models';
import { PAGE_CORRECT_DOMAIN_SETTINGS_MODULE } from '@monsido/modules/page-fix/constants/domain-settings-modules.constant';
import { MON_MODULES } from '@monsido/core/constants/mon-modules.constant';
import { User } from '@monsido/ng2/modules/models/api/user';
import { PlanTraits } from '@monsido/ng2/modules/models/api/interfaces/customer.interface';
import { EssentialDomainDataModel } from '@client/app/services/total-domains/essential-domain-data.model';

@Injectable()
export class ActiveFeatureService {
    constructor (private sessionService: SessionService) {}

    isFeatureActive (feature: string, domain?: Domain, page?: Page): boolean {
        domain = domain ?? this.sessionService.domain ?? undefined;
        if (this.sessionService.customer) {
            if (this.sessionService.isLiteCustomer()) {
                return this.hasLightAccountAccessToFeature(feature, domain);
            }
            return this.hasAccountAccessToFeature(feature, domain, page);
        }
        return false;
    }

    isFeatureActivePure (feature: string): boolean {
        if (this.sessionService.customer) {
            if (this.sessionService.isLiteCustomer()) {
                return this.hasLightAccountAccessToFeature(feature);
            }
            return this.hasAccountAccessToFeature(feature);
        }
        return false;
    }

    private hasLightAccountAccessToFeature (feature: string, domain?: Domain): boolean {
        if (!this.sessionService.customer) {
            return false;
        }

        const planTraits = this.sessionService.customer.plan_traits || {};

        switch (feature) {
            case 'page_assist':
                return activeFeatures[feature] && planTraits.page_assist;
            case 'admin_accessibility':
                return planTraits.accessibility || false;
            case 'accessibility':
                return planTraits.accessibility && domain?.features.accessibility !== null || false;
            case 'page_fix':
                return planTraits.page_fix && domain?.features.page_fix || false;
            case 'cookie_banner':
                return this.hasAccessToConsentManager(planTraits, domain);
            case 'support_center':
            case 'customer_admin':
            case 'admin_heatmaps':
            case 'velaro':
            case 'shortcuts':
            case 'general_book_training':
            case 'data_export':
            case 'suggestions_and_ideas':
            case 'classic_ui':
                return false;
            default:
                return false;
        }
    }

    private hasAccountAccessToFeature (feature: string, domain?: Domain, page?: Page): boolean {
        if (!this.sessionService.customer) {
            return false;
        }
        const planTraits = this.sessionService.customer.plan_traits || {};

        switch (feature) {
            case 'api_users':
            case 'general_book_training':
            case 'mobile_guidelines':
            case 'data_export':
            case 'history_center_all':
            case 'seo_all':
            case 'general_shortcuts':
            case 'policies_all':
            case 'policy_bot':
            case 'prioritized_content_all':
            case 'report_center_all':
            case 'general_chat_support':
            case 'inventory_all':
            case 'qa_links':
            case 'qa_spellcheck':
            case 'script_setup_guide':
            case 'accessibility_summary':
            case 'page_details':
            case 'qa_summary':
            case 'quick_search':
            case 'dashboard_important_notifications':
            case 'account_settings':
            case 'on_demand_scans':
            case 'monsido_score':
            case 'clarity_pdf_remediation':
            case 'advanced_analytics':
                return Boolean(planTraits[feature]);
            case 'cookie_banner':
                return this.hasAccessToConsentManager(planTraits, domain);
            case 'statistics':
                return planTraits.statistics && domain?.features.statistics || false;
            case 'admin_readability':
                return activeFeatures.readability && planTraits.readability;
            case 'readability':
                return planTraits.readability && domain?.features.readability_test !== null && activeFeatures.readability;
            case 'page_fix':
                return Boolean(
                    activeFeatures.page_fix &&
                    planTraits.page_fix &&
                    (domain?.features.page_fix || false) &&
                    this.hasAccessToPageFix(domain) &&
                    domain.settings &&
                    domain.settings.scripts?.page_correct?.enabled_checks &&
                    domain.settings.scripts.page_correct.enabled_checks.length > 0 &&
                    this.sessionService.domainGroup === null &&
                    this.hasAccess(this.sessionService.me, MON_MODULES.pagecorrect),
                );
            case 'page_fix_accessibility_check':
                return Boolean(
                    domain &&
                    this.hasAccountAccessToFeature('page_fix', domain, page) &&
                    this.hasPageFixEnabledCheck(domain, PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK),
                );
            case 'page_fix_accessibility_source_code':
                return Boolean(
                    domain &&
                    this.hasAccountAccessToFeature('page_fix', domain, page) &&
                    this.hasPageFixEnabledCheck(domain, PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_SOURCE_CODE),
                );
            case 'page_fix_qa_links':
                return Boolean(
                    domain &&
                    this.hasAccountAccessToFeature('page_fix', domain, page) &&
                    this.hasPageFixEnabledCheck(domain, PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.LINK),
                );
            case 'page_fix_qa_misspelling':
                return Boolean(
                    domain &&
                    this.hasAccountAccessToFeature('page_fix', domain, page) &&
                    this.hasPageFixEnabledCheck(domain, PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.SPELLING_ERROR),
                );
            case 'page_fix_qa_custom':
                return Boolean(
                    domain &&
                    this.hasAccountAccessToFeature('page_fix', domain, page) &&
                    this.hasPageFixEnabledCheck(domain, PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.CUSTOM),
                );
            case 'page_assist':
                return activeFeatures.page_assist && planTraits.page_assist && (domain?.features.page_assist || false);
            case 'heatmaps':
            case 'admin_heatmaps':
                return (planTraits.heatmap_click || planTraits.heatmap_movement || planTraits.heatmap_scroll);
            case 'accessibility':
                return Boolean(planTraits.accessibility && domain && domain.features.accessibility !== null);
            case 'admin_accessibility':
                return planTraits.accessibility;
            case 'admin_source_code_excludes':
                return planTraits.source_code_excludes;
            case 'admin_page_fix':
                return activeFeatures.page_fix && planTraits.page_fix && this.hasAccess(this.sessionService.me, MON_MODULES.pagecorrect);
            case 'admin_page_assist':
                return activeFeatures.page_assist && planTraits.page_assist;
            case MON_MODULES.data_privacy: {
                const dataProtection = (domain instanceof EssentialDomainDataModel)
                    ? (domain as EssentialDomainDataModel).features?.dataProtection
                    : domain?.features?.data_protection;
                return activeFeatures.data_privacy && planTraits.data_protection && (dataProtection || false);
            }
            case 'admin_data_privacy':
                return activeFeatures.data_privacy && planTraits.data_protection;
            case 'page_performance': // Domain Check
                return Boolean(this.hasAccountAccessToFeature('admin_page_performance', domain, page) && domain && domain.scan.login?.type === 'none');
            case 'admin_page_performance': // Customer Admin Check
                return activeFeatures.page_performance && planTraits.performance_tracking;
            case 'page_page_performance': // Page Check
                return Boolean(this.hasAccountAccessToFeature('page_performance', domain, page) && page && page.performance_scores !== null);
            case 'admin_accessibility_help_center': // Page Check
                return activeFeatures.accessibility_help_center;
            case 'customer_admin':
                return this.sessionService.hasRole('customer_admin');
            case 'qa_module':
                return planTraits.readability || planTraits.qa_links || planTraits.qa_spellcheck;
            case 'admin_heartbeat':
                return planTraits.heartbeat && this.hasAccess(this.sessionService.me, MON_MODULES.uptime);
            case 'admin_statistics':
                return planTraits.statistics && this.hasAccess(this.sessionService.me, MON_MODULES.statistics);
            case 'custom_scan':
                return planTraits.custom_scan_interval;
            case 'image_metadata':
                return planTraits.image_metadata;

            case 'suggestions_and_ideas':
            case 'velaro':
            case 'support_center':
                return true;
            default:
                return activeFeatures[feature] || false;
        }
    }

    private hasPageFixEnabledCheck (domain: Domain, check: string): boolean {
        const settings = domain.settings;
        if (settings) {
            const pageCorrect = settings.scripts?.page_correct;
            if (pageCorrect) {
                return pageCorrect.enabled_checks.indexOf(check) > -1;
            }
        }

        return false;
    }

    private hasAccessToPageFix (domain: Domain): boolean {
        const settings = domain.settings;
        if (settings) {
            if (settings.scripts?.page_correct?.admin_only) {
                return this.sessionService.hasRole('customer_admin');
            }
            // If admin_only is falsy, it's safe to assume user has access
            return true;
        }

        return false;
    }

    private hasAccessToConsentManager (planTraits: PlanTraits, domain?: Domain): boolean {
        return planTraits.cookie_banner && domain?.features.cookie_banner || false;
    }

    private hasAccess (user: User | undefined | null, module: string): boolean {
        if (!user) {
            return false;
        }

        let canAccessModule = true;

        switch (module) {
            case MON_MODULES.data_privacy:
            case MON_MODULES.cookie_banner:
                canAccessModule = this.isFeatureActive(module);
                break;
            case MON_MODULES.accessibility:
                canAccessModule = Boolean(!this.sessionService.customer || this.sessionService.customer.plan_traits.accessibility);
                break;
            case MON_MODULES.pagecorrect:
                canAccessModule = Boolean(!this.sessionService.customer || this.sessionService.customer.plan_traits.page_fix);
                break;
        }

        // Users without any modules is allowed access to all modules in the migration period
        if (user.settings?.modules && user.settings.modules.length > 0) {
            return canAccessModule && user.settings.modules.indexOf(module) === -1;
        }

        return canAccessModule;
    }
}
