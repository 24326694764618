import { Injectable } from '@angular/core';
import { ApiClient } from '@monsido/modules/endpoints/api/api-client';
import { StatisticsEventsDemoRepoService } from './statistics-events.demo.repo.service';
import { SessionService } from '@monsido/core/session/session.service';
import { IsStatsDemoDataPipe } from '@monsido/modules/statistics/pipes/is-stats-demo-data/is-stats-demo-data.pipe';
import { StatisticsEventsAction, StatisticsEventsCategoryOrName } from '@monsido/modules/statistics/models/statistics-events.type';
import { contextTokens, RequestAuxiliaryService } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

@Injectable({
    providedIn: 'root',
})
export class StatisticsEventsRepoService {
    constructor (
        private client: ApiClient,
        private statsEventsDemoRepo: StatisticsEventsDemoRepoService,
        private IsStatsDemoData: IsStatsDemoDataPipe,
        private sessionService: SessionService,
        private requestAuxiliaryService: RequestAuxiliaryService,
    ) {}

    getEventsCategories (domainId: number, params: { [key: string]: string | number }): Promise<StatisticsEventsCategoryOrName[]> {
        if (this.useDemoData()) {
            return this.statsEventsDemoRepo.getEventsCategories();
        }

        const context = this.requestAuxiliaryService.setHttpContext(contextTokens.NO_PARSE_SEARCH);
        return this.client.getPromise(`domains/${domainId}/statistics/events/categories`,
            {
                params: params,
                context,
            });
    }

    getEventsActions (domainId: number, params: { [key: string]: string | number }): Promise<StatisticsEventsAction[]> {
        if (this.useDemoData()) {
            return this.statsEventsDemoRepo.getEventsActions();
        }
        const context = this.requestAuxiliaryService.setHttpContext(contextTokens.NO_PARSE_SEARCH);
        return this.client.getPromise(`domains/${domainId}/statistics/events/actions`,
            {
                params: params,
                context,
            });
    }

    getEventsNames (domainId: number, params: { [key: string]: string | number }): Promise<StatisticsEventsCategoryOrName[]> {
        if (this.useDemoData()) {
            return this.statsEventsDemoRepo.getEventsNames();
        }
        const context = this.requestAuxiliaryService.setHttpContext(contextTokens.NO_PARSE_SEARCH);
        return this.client.getPromise(`domains/${domainId}/statistics/events/names`,
            {
                params: params,
                context,
            });
    }

    private useDemoData (): boolean {
        return this.IsStatsDemoData.transform(this.sessionService.domain);
    }
}
