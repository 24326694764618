<div class="suggestions-wrapper">
    <div class="suggestion-section-text">I am your Acquia Optimize policy assistant. Not sure what to ask me? Here are a couple of prompts to get you started:</div>
    <div class="suggestion-section-title">Suggestions</div>
    <div class="suggestions-list">
        @for (suggestion of suggestions; track suggestion) {
            <button role="button"
                    class="suggestion-button"
                    aria-describedby="suggestion-button-help"
                    (click)="select.emit(suggestion)">
                <span class="suggestion-button-title">{{ suggestion.title }}</span>
                <span class="suggestion-button-premable">{{ suggestion.premable }}</span>
            </button>
        }
    </div>
    <div id="suggestion-button-help" hidden>
        Select a suggestion prompt to get started with Acquia Optimize Policy assistant.
    </div>
</div>
