import {
    Component,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ScrollableDropdownMenuItem, ScrollableDropdownOnSelectPayload, ScrollableDropdownOnClosePayload } from './scrollable-dropdown-menu.interface';

@Component({
    selector: 'mon-scrollable-dropdown-menu',
    templateUrl: 'scrollable-dropdown-menu.html',
    styleUrls: ['scrollable-dropdown-menu.scss'],
})
export class MonScrollableDropdownMenuComponent implements AfterViewInit {
    @Input() monMenuItems: ScrollableDropdownMenuItem[] = [];
    @Input() monLabelledBy: string = '';
    @Input() monId: string = '';
    @Input() monFocusFirstMenuItem: boolean = false;

    @Output() monOnSelect: EventEmitter<ScrollableDropdownOnSelectPayload> = new EventEmitter<ScrollableDropdownOnSelectPayload>();
    @Output() monOnClose: EventEmitter<ScrollableDropdownOnClosePayload> = new EventEmitter<ScrollableDropdownOnClosePayload>();

    @ViewChild('scrollContainerRef') scrollContainerRef?: ElementRef;

    private focusingIndex: number | null = null;
    private menuItemEls: HTMLElement[] = [];
    private scrollContainer: HTMLElement | null = null;

    ngAfterViewInit (): void {
        this.scrollContainer = this.scrollContainerRef?.nativeElement;
        this.menuItemEls = Array.from(
            this.scrollContainer?.querySelectorAll(
                '.mon-scrollable-dropdown-menu-item',
            ) || [],
        ) as HTMLElement[];
        this.setupEventListeners();

        if (this.monFocusFirstMenuItem) {
            this.focusingIndex = 0;
            this.focusMenuItemAtIndex(this.focusingIndex);
        } else {
            this.scrollContainer?.focus();
        }
    }

    onMenuItemClick (menuItem: ScrollableDropdownMenuItem): void {
        this.monOnSelect.emit({ data: menuItem });
        this.monOnClose.emit();
    }

    private setupEventListeners (): void {
        if (this.scrollContainer) {
            this.scrollContainer.addEventListener(
                'keydown',
                this.onDropdownKeydown.bind(this),
            );
        }
    }

    private onDropdownKeydown (event: KeyboardEvent): void {
        if (event.ctrlKey || event.altKey || event.metaKey) {
            return;
        }
        const preventScrollingKey = [
            'ArrowUp',
            'ArrowDown',
            'Escape',
            'Tab',
            'Enter',
            ' ',
        ];
        const key = event.key;
        if (preventScrollingKey.indexOf(key) > -1) {
            event.preventDefault();
            event.stopPropagation();
            switch (key) {
                case 'ArrowDown':
                    this.setFocusNext();
                    if (this.focusingIndex !== null) {
                        this.focusMenuItemAtIndex(this.focusingIndex);
                    }
                    break;
                case 'ArrowUp':
                    this.setFocusPrevious();
                    if (this.focusingIndex !== null) {
                        this.focusMenuItemAtIndex(this.focusingIndex);
                    }
                    break;
                case 'Escape':
                    this.monOnClose.emit({
                        recoverFocus: true,
                    });
                    break;
                case 'Tab':
                    this.monOnClose.emit({
                        recoverFocus: false,
                    });
                    break;
                case 'Enter':
                case ' ':
                    if (this.focusingIndex !== null) {
                        const focusingItem = this.monMenuItems[this.focusingIndex];
                        if (focusingItem) {
                            this.onMenuItemClick(focusingItem);
                        }
                    }
                    this.monOnClose.emit({
                        recoverFocus: true,
                    });
                    break;
                default:
                    break;
            }
        }
    }

    private setFocusNext (): void {
        if (this.focusingIndex === null) {
            this.focusingIndex = 0;
            return;
        }
        this.focusingIndex += 1;
        if (this.focusingIndex >= this.menuItemEls.length) {
            this.focusingIndex = 0;
        }
    }

    private setFocusPrevious (): void {
        if (this.focusingIndex === null) {
            this.focusingIndex = this.menuItemEls.length - 1;
            return;
        }
        this.focusingIndex -= 1;
        if (this.focusingIndex < 0) {
            this.focusingIndex = this.menuItemEls.length - 1;
        }
    }

    private focusMenuItemAtIndex (index: number): void {
        const toBeFocusedEl = this.menuItemEls[index];
        if (toBeFocusedEl) {
            const shouldScroll = !this.isElVisibleInDropdownViewport(toBeFocusedEl);
            toBeFocusedEl.focus({
                preventScroll: true,
            });
            if (shouldScroll) {
                toBeFocusedEl.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        }
    }

    private isElVisibleInDropdownViewport (el: HTMLElement): boolean {
        const elRect = el.getBoundingClientRect();
        const dropdownRect = this.scrollContainer?.getBoundingClientRect();

        if (dropdownRect) {
            return elRect.top >= dropdownRect.top && elRect.top + elRect.height <= dropdownRect.bottom;
        }
        return false;
    }
}
