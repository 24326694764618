import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../shared/shared.module';
import { ActionMenuItemType } from '@monsido/ng2/shared/components/action-menu-panel/action-menu-panel.type';
import { MonEventService } from '@monsido/ng2/services/mon-event/mon-event.service';
import { DataPrivacyViolationType } from '../../interfaces/data-privacy-violation.type';
import { TranslateService } from '@client/app/services/translate/translate.service';

@Component({
    selector: 'mon-data-privacy-dropdown',
    standalone: true,
    templateUrl: './dropdown.component.html',
    imports: [CommonModule, SharedModule],
})
export class MonDataPrivacyDropdownComponent implements OnChanges {
    @Input() monViolation?: DataPrivacyViolationType;
    @Input() monActiveTab: string = '';
    @Input() monUpdateCallback?: (status: string, violation: DataPrivacyViolationType, confirmMessage: string) => void;

    options: ActionMenuItemType[] = [];

    constructor (private translateService: TranslateService, private eventsService: MonEventService) {}

    ngOnChanges (): void {
        const dropdown = [
            {
                label: this.translateService.getString('Ignore on the page'),
                leftIcon: 'faEyeSlash',
                action: (): void => this.action('ignored', this.translateService.getString('Are you sure you want to ignore this issue?')),
                eventParams: { action: 'data-privacy-violation-ignored' },
                shouldShow: this.monActiveTab !== 'fixed' && this.monActiveTab !== 'ignored',
            },
            {
                label: this.translateService.getString('Mark as fixed'),
                leftIcon: 'faCheck',
                action: (): void => this.action('fixed', this.translateService.getString('Are you sure you want to mark this issue as fixed?')),
                eventParams: { action: 'data-privacy-violation-fixed' },
                shouldShow: this.monActiveTab !== 'fixed' && this.monActiveTab !== 'ignored',
            },
            {
                label: this.translateService.getString('Mark as not fixed'),
                leftIcon: 'faTimes',
                action: (): void => this.action('active', this.translateService.getString('Are you sure you want to mark this issue as not fixed?')),
                eventParams: { action: 'data-privacy-violation-not-fixed' },
                shouldShow: this.monActiveTab === 'fixed',
            },
            {
                label: this.translateService.getString('Unignore'),
                leftIcon: 'faEye',
                action: (): void => this.action('active', this.translateService.getString('Are you sure you want to unignore this issue?')),
                eventParams: { action: 'data-privacy-violation-unignored' },
                shouldShow: this.monActiveTab === 'ignored',
            },
        ];

        this.options = dropdown.filter((option) => option.shouldShow !== false).map(item => {
            return {
                label: item.label,
                leftIcon: item.leftIcon,
                action: (): void => {
                    this.eventsService.run('actionTrigger', item.eventParams);

                    item.action();
                },
            };
        });
    }

    action (status: string, message: string): void {
        if (!this.monViolation || !this.monUpdateCallback) {
            return;
        }
        this.monUpdateCallback(status, this.monViolation, message);
    }
}
