(function () {
    'use strict';

    angular.module('app.forms').component('formGlobalPolicyDialogSave', {
        templateUrl: 'app/forms/global-policy/dialogs/save/save.html',
        controller: PolicyRuleController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    PolicyRuleController.$inject = ['policyService', '$timeout'];
    /* @ngInject */
    function PolicyRuleController (policyService, $timeout) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;

        function activate () {
            vm.policy = vm.resolve.policy;
            vm.saving = false;
        }

        function submit () {
            var promise;
            vm.saving = true;

            if (vm.policy.id) {
                promise = policyService.update(vm.policy);
            } else {
                promise = policyService.create(vm.policy);
            }

            promise
                .then(
                    function (policy) {
                        policyService.run(policy.id).then(angular.noop, angular.noop);
                        return policy;
                    },
                    function () {
                        vm.saving = false;
                    },
                )
                .then(function (policy) {
                    $timeout(vm.close({ $value: policy }), 200); // Some policies takes a little to start for the api
                }, angular.noop);
        }
    }
})();
