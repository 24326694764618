import { Injectable } from '@angular/core';
import { PolicyRepoRuleOperatorType, PolicyRepoRuleType } from '@client/app/services/api/policy-repo/policy.types';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { FORM_CONSTANT } from '@client/app/forms/constants/form';
import { StateService } from '@uirouter/core';
import { SessionService } from '@monsido/core/session/session.service';
import { PolicyModel } from '@client/app/services/api/policy-repo/policy-model';

@Injectable({
    providedIn: 'root',
})
export class ChatBotPolicyResultService {

    constructor (
        private monEventsService: MonEventService,
        private stateService: StateService,
        private sessionService: SessionService,
    ) {}

    async viewPolicyRules (
        name: string,
        subject: string,
        rules: PolicyRepoRuleType[],
        rulesOperator: PolicyRepoRuleOperatorType = 'and',
    ): Promise<void | void[]> {
        const policy = {
            name,
            subject,
            created_by_ai: true,
            labels: [],
            targets: [],
            rules,
            rules_operator: rulesOperator,
        };

        return this.monEventsService.run(MON_EVENTS.LOAD_DIALOG, {
            params: {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
                data: {
                    policy,
                    createdByAi: true,
                },
            },
            callback: (createdPolicy?: PolicyModel) => {
                if (createdPolicy) {
                    const customerId = this.sessionService.customer && this.sessionService.customer.id;
                    this.stateService.go('base.customer.global.policy.policies', {
                        customerId,
                        search: createdPolicy.name,
                    });
                }
            },
        });
    }

}
