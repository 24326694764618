import { Label } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { cloneDeep } from 'lodash';
import { PolicyRepoAuthorType, PolicyRepoMatchType, PolicyRepoModeType, PolicyRepoPriorityType, PolicyRepoRuleOperatorType, PolicyRepoRuleType, PolicyRepoStateType, PolicyRepoSubjectType, PolicyRepoTagType, PolicyRepoTargetType, PolicyRepoTargetTypeType, PolicyRepoType } from './policy.types';


export class PolicyModel implements PolicyRepoType {
    archived_at: string;
    created_at?: string;
    created_by: PolicyRepoAuthorType;
    id?: number;
    ignored: boolean;
    labels: Label[] = [];
    links_count: number;
    matches: PolicyRepoMatchType[] = [];
    matches_count: number;
    mode: PolicyRepoModeType;
    name?: string;
    note: string;
    pages_count: number;
    priority: PolicyRepoPriorityType;
    rules: PolicyRepoRuleType[] = [];
    rules_operator: PolicyRepoRuleOperatorType;
    scheduled: boolean;
    state: PolicyRepoStateType;
    subject: PolicyRepoSubjectType;
    tags: PolicyRepoTagType[];
    target_all_domains: boolean;
    targets: PolicyRepoTargetType[] = [];
    targetType: PolicyRepoTargetTypeType;
    template: string;
    updated_at?: string;
    created_by_ai: boolean;

    constructor (attributes: PolicyRepoType, targetType: PolicyRepoTargetTypeType) {
        this.targetType = targetType;
        this.archived_at = attributes.archived_at;
        this.created_at = attributes.created_at;
        this.created_by = attributes.created_by;
        this.id = attributes.id;
        this.ignored = attributes.ignored;
        attributes.labels.forEach((label) => {
            this.labels.push({
                color: label.color,
                created_at: label.created_at,
                id: label.id,
                name: label.name,
                updated_at: label.updated_at,
                excluded: label.excluded,
            });
        });
        this.links_count = attributes.links_count;
        attributes.matches?.forEach((match) => {
            this.matches.push({
                name: match.name,
                type: match.type,
                matches: match.matches,
            });
        });
        this.matches_count = attributes.matches_count;
        this.mode = attributes.mode;
        this.name = attributes.name;
        this.note = attributes.note;
        this.pages_count = attributes.pages_count;
        this.priority = attributes.priority;
        attributes.rules.forEach((rule) => {
            this.rules.push({
                case_sensitive: rule.case_sensitive,
                css_filters: rule.css_filters ? {
                    exclude: [...rule.css_filters.exclude],
                    limit: [...rule.css_filters.limit],
                } : rule.css_filters,
                inverse: rule.inverse,
                multiline: rule.multiline,
                name: rule.name,
                operator: rule.operator,
                query: rule.query,
                sub_entry: rule.sub_entry,
                type: rule.type,
            });
        });
        this.rules_operator = attributes.rules_operator;
        this.scheduled = attributes.scheduled;
        this.state = attributes.state;
        this.subject = attributes.subject;
        this.tags = [...attributes.tags];
        this.target_all_domains = attributes.target_all_domains;
        attributes.targets.forEach((target) => {
            this.targets.push({
                id: target.id,
                latest_run_at: target.latest_run_at,
                links_count: target.links_count,
                matches_count: target.matches_count,
                pages_count: target.pages_count,
                title: target.title,
                type: target.type,
            });
        });
        this.template = attributes.template;
        this.updated_at = attributes.updated_at;
        this.created_by_ai = attributes.created_by_ai;
        this.setPagesAndMatchesCount();
    }

    clone (): PolicyModel {
        const clone = cloneDeep(this);
        delete clone.updated_at;
        delete clone.created_at;
        delete clone.id;
        delete clone.name;
        return clone;
    }

    private setPagesAndMatchesCount (): void {
        if (this.state === 'running') {
            this.setupRunningPolicyData();
        }
    }

    private setupRunningPolicyData (): void {
        this.pages_count = 0;
        this.matches_count = 0;
        this.links_count = 0;
        this.targets = this.targets.map((domain) => {
            domain.matches_count = 0;
            domain.pages_count = 0;
            domain.links_count = 0;
            return domain;
        });
    }
}
