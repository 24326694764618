import { ChangeDetectionStrategy, Component, computed, output, signal, Signal, WritableSignal } from '@angular/core';
import { PolicyChatBotFeedbackReactionValueType } from '@client/app/modules/chat-bot/types/policy.type';

type ReactionValueType = '' | PolicyChatBotFeedbackReactionValueType;

@Component({
    selector: 'mon-evaluation-widget',
    standalone: true,
    imports: [],
    templateUrl: './evaluation-widget.component.html',
    styleUrl: './evaluation-widget.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaluationWidgetComponent {

    reaction: WritableSignal<ReactionValueType> = signal('');
    reactionDescriptions: Signal<string[]> = computed(() => {
        if (this.reaction() === 'thumbs-up') {
            return this.positiveReactionDescriptions;
        }
        if (this.reaction() === 'thumbs-down') {
            return this.negativeReactionDescriptions;
        }
        return [];
    });
    choiceMade = signal('');
    choice = output<[string, PolicyChatBotFeedbackReactionValueType]>();

    constructor () {
        this.choice.subscribe(([value]: [string, PolicyChatBotFeedbackReactionValueType]) => {
            this.choiceMade.set(value);
        });
    }

    setReaction (reaction: PolicyChatBotFeedbackReactionValueType): void {
        if (this.reaction() === reaction) {
            this.reaction.set('');
        } else {
            this.reaction.set(reaction);
        }
    }

    emitChoice (message: string): void {
        this.choice.emit([
            message,
            this.reaction() as PolicyChatBotFeedbackReactionValueType,
        ]);
    }

    private positiveReactionDescriptions = [
        'Useful',
        'Correct',
    ];
    private negativeReactionDescriptions = [
        'Not useful',
        'Incorrect',
        'Long response time',
        'Misunderstood question',
    ];

}
