<div class="assistant-wrapper">
    @if (chatStarted()) {
        <div class="chat-messages" #chatMessages role="log" aria-label="chat messages">
            @for (message of messages(); track $index; let msgIndex = $index) {
                <div [attr.data-sender]="message.sender">
                    <span>{{message.text}}</span>
                </div>
                @if (message.policies) {
                    @for (policy of message.policies; track $index) {
                        <div class="bot-rule">
                            <mon-bot-view-policy [botViewPolicy]="policy" (viewPolicy)="onViewPolicy($event)"></mon-bot-view-policy>
                        </div>
                        <mon-evaluation-widget class="mb-2" (choice)="onFeedbackChoiceSelected($event, msgIndex)"></mon-evaluation-widget>
                    }
                }
            }
            @if (messageBeingProcessed()) {
                <div [attr.data-sender]="'bot'">
                    <span>...</span>
                </div>
            }
        </div>
    } @else {
        <mon-bot-greetings
            [hasUserAgreement]="hasUserAgreement()"
            [userAuthenticated]="userAuthenticated()"
            (userAgreed)="onUserAgreed()"></mon-bot-greetings>
        @if (userAuthenticated()) {
            <mon-bot-suggestions (select)="onSuggestionSelected($event)"></mon-bot-suggestions>
        }
    }
    @if (userAuthenticated()) {
        <div class="text-input-wrapper">
            <form (submit)="onTextSubmit($event)">
                <textarea
                    placeholder="Message Policy Assistant"
                    #userText
                    (change)="onTextInput()"
                    (keyup)="onTextInput()"></textarea>
                <button type="submit"
                    class="submit-user-input"
                    aria-label="Submit message"
                    [attr.aria-disabled]="messageBeingProcessed()"
                    >
                    <img src="/images/send-icon.svg" alt="">
                </button>
                <span class="input-disclaimer">The Policy Assistant runs on Open AI, which can make mistakes.</span>
            </form>
        </div>
    }
</div>
