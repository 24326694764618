import { ChangeDetectionStrategy, Component, ElementRef, HostListener, signal, ViewChild, inject, OnInit, DestroyRef } from '@angular/core';
import { BotGreetingsComponent } from '../bot-greetings/bot-greetings.component';
import { BotViewPolicyComponent } from '../bot-view-policy/bot-view-policy/bot-view-policy.component';
import { BotViewPoliciesType, BotViewPolicyType, PolicyChatBotFeedbackReactionValueType } from '../../types/policy.type';
import { EvaluationWidgetComponent } from '../evaluation-widget/evaluation-widget.component';
import { BotSuggestionsComponent } from '@client/app/modules/chat-bot/components/bot-suggestions/bot-suggestions.component';
import { BotSuggestionEntryType } from '@client/app/modules/chat-bot/components/bot-suggestions/bot-suggestions.type';
import { ChatBotPolicyRepoService } from '@client/app/modules/chat-bot/services/chat-bot-policy-repo.service';
import { ChatBotPolicyResultService } from '@client/app/modules/chat-bot/services/chat-bot-policy-result.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalStorageService } from '@angular-shared-components';

type ChatMessageType = {
    text: string;
    sender: 'bot' | 'user';
    policies?: BotViewPoliciesType['policies']
}

@Component({
    selector: 'mon-chat-bot',
    standalone: true,
    imports: [
        BotGreetingsComponent,
        BotViewPolicyComponent,
        EvaluationWidgetComponent,
        BotSuggestionsComponent,
    ],
    templateUrl: './chat-bot.component.html',
    styleUrl: './chat-bot.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBotComponent implements OnInit {
    @ViewChild('userText') userText?: ElementRef<HTMLTextAreaElement>;
    @ViewChild('chatMessages') chatMessages?: ElementRef<HTMLDivElement>;

    @HostListener('keydown.enter', ['$event'])
    handleKeyDown (event: KeyboardEvent): void {
        if (event.target === this.userText?.nativeElement) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.sendTextToBot();
        }
    }

    private chatBotPolicyRepoService = inject(ChatBotPolicyRepoService);
    private chatBotPolicyResultService = inject(ChatBotPolicyResultService);
    private localStorageService = inject(LocalStorageService);
    private destroyRef = inject(DestroyRef);

    messages = signal<ChatMessageType[]>([]);
    chatStarted = signal(false);
    hasUserAgreement = signal(false);
    userAuthenticated = signal(false);
    messageBeingProcessed = signal(false);

    chatId: number | null = null;
    private ChatBotPolicyUserAgreementAcceptedKey = 'chatBotPolicyUserAgreementAccepted';

    ngOnInit (): void {
        const userAgreementAccepted = this.localStorageService.getItem(this.ChatBotPolicyUserAgreementAcceptedKey);
        if (userAgreementAccepted === 'true') {
            this.onUserAgreed();
        }
    }

    async onViewPolicy (policy: BotViewPolicyType): Promise<void> {
        await this.chatBotPolicyResultService.viewPolicyRules(
            policy.name,
            policy.subject,
            policy.rules,
            policy.rules_operator,
        );
    }

    onUserAgreed (): void {
        this.localStorageService.setItem(this.ChatBotPolicyUserAgreementAcceptedKey, 'true');
        this.hasUserAgreement.set(true);
        this.chatBotPolicyRepoService
            .createChat()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(res => {
                this.chatId = res.id;
                this.userAuthenticated.set(true);
            });
    }

    onTextInput (): void {
        const textarea = this.userText?.nativeElement;
        if (!textarea) {
            return;
        }
        textarea.style.setProperty('height', '40px');
        textarea.style.setProperty('height', textarea.scrollHeight + 2 + 'px');
    }

    onTextSubmit (e: Event): void {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.sendTextToBot();
    }

    onSuggestionSelected (suggestion: BotSuggestionEntryType): void {
        if (this.userText?.nativeElement) {
            this.userText.nativeElement.value = suggestion.prompt;
            this.sendTextToBot();
        }
    }

    onFeedbackChoiceSelected (
        [reason, type]: [string, PolicyChatBotFeedbackReactionValueType],
        msgIndex: number,
    ): void {
        if (!this.chatId) {
            return;
        }

        this.chatBotPolicyRepoService
            .addFeedback(
                this.chatId,
                {
                    reason,
                    type,
                    policies_index: this.calculatePolicyGroupIndex(msgIndex),
                },
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe();
    }

    private calculatePolicyGroupIndex (msgIndex: number): number {
        let result = 0;
        for (let i = 0; i <= msgIndex; i++) {
            const msg = this.messages()[i];
            if (msg.policies && msg.policies.length > 0) {
                result++;
            }
        }

        return result;
    }

    private sendTextToBot (): void {
        if (this.messageBeingProcessed()) {
            return;
        }
        const textarea = this.userText?.nativeElement;
        if (!textarea) {
            return;
        }
        const message = textarea.value.trim();
        if (!message) {
            return;
        }
        this.chatStarted.set(true);
        this.messageBeingProcessed.set(true);
        textarea.value = '';
        this.onTextInput();

        this.addMessage({
            sender: 'user',
            text: message,
        });

        if (!this.chatId) {
            return;
        }

        this.chatBotPolicyRepoService
            .addMessage(this.chatId, message)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((res: BotViewPoliciesType) => {
                this.processBotResponse(res);
            }, err => {
                this.messageBeingProcessed.set(false);
            });
    }

    private processBotResponse (botResponsePayload: BotViewPoliciesType): void {
        const message: ChatMessageType = {
            sender: 'bot',
            text: botResponsePayload.bot_response,
        };
        if (botResponsePayload.policies) {
            message.policies = botResponsePayload.policies;
        }
        this.addMessage(message);
        this.messageBeingProcessed.set(false);
    }

    private addMessage (message: ChatMessageType): void {
        const messages = this.messages();
        messages.push(message);
        this.messages.set(messages);

        setTimeout(() => {
            const chatMessageElement = this.chatMessages?.nativeElement;
            if (chatMessageElement) {
                chatMessageElement.scrollTo({ left: 0, top: chatMessageElement.scrollHeight, behavior: 'smooth' });
            }
        });
    }

}
