import { ChangeDetectionStrategy, Component, input, computed, OnInit, Output, EventEmitter } from '@angular/core';
import { POLICY_FORM_HELPER_DB } from '@client/app/core/constants/policy-form-helper-db';
import { BotViewPolicyType } from '../../../types/policy.type';
import { NgClass } from '@angular/common';
import { TranslateService } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { PolicyRepoRuleType } from '@client/app/services/api/policy-repo/policy.types';


@Component({
    selector: 'mon-bot-view-policy',
    standalone: true,
    imports: [NgClass],
    templateUrl: './bot-view-policy.component.html',
    styleUrl: './bot-view-policy.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotViewPolicyComponent implements OnInit {
    @Output() viewPolicy = new EventEmitter<BotViewPolicyType>();
    botViewPolicy = input.required<BotViewPolicyType>();
    rules = computed(() => {
        const rules: (PolicyRepoRuleType & { icon: string })[] = [];
        this.botViewPolicy().rules.forEach((rule) => {
            rules.push( { ...rule, icon: this.getRuleIcon(rule.type) });
        });
        return rules;
    });
    policyFormHelperDb: typeof POLICY_FORM_HELPER_DB;
    policyRulesOperator: string = '';

    constructor (private translateService: TranslateService) {
        this.policyFormHelperDb = POLICY_FORM_HELPER_DB;
    }

    ngOnInit (): void {
        this.policyRulesOperator = this.botViewPolicy().rules_operator;
    }

    onViewPolicy (): void {
        if (this.botViewPolicy()) {
            this.viewPolicy.emit(this.botViewPolicy());
        }
    }

    getOperatorTranslation (rule: PolicyRepoRuleType): string {
        const currentOperator = this.policyFormHelperDb.RULE_TYPE_OPTIONS[rule.type]?.find((operator) => operator === rule.operator);
        if (currentOperator) {
            return this.translateService.getString(this.policyFormHelperDb.TRANSLATIONS.RULE_TYPE_OPTIONS[currentOperator]);
        }
        return '';
    };

    isRuleTextType (ruleType): boolean {
        const ruleTypes = [
            'page_html',
            'page_text',
            'page_title',
            'header_text',
            'page_url',
            'image_text',
            'link_text',
            'link_url',
        ];
        return ruleTypes.indexOf(ruleType) > -1;
    }

    private getRuleIcon (ruleType: string): string {
        return this.policyFormHelperDb.RAW_ICONS.SUBJECT_OPTIONS[ruleType];
    }
}
