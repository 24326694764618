@let reactionValue = reaction();
@let choiceMadeValue = choiceMade();
<div class="wrapper">
    <button type="button" aria-label="Thumb up" (click)="choiceMadeValue === '' && setReaction('thumbs-up')" [attr.aria-pressed]="reactionValue === 'thumbs-up'" [attr.aria-disaled]="choiceMadeValue !== ''">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_526_1644" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="16" height="16" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_526_1644)">
            <path d="M12.0007 14.0003H5.33398V5.33366L10.0007 0.666992L10.834 1.50033C10.9118 1.5781 10.9757 1.68366 11.0257 1.81699C11.0757 1.95033 11.1007 2.0781 11.1007 2.20033V2.43366L10.3673 5.33366H14.0007C14.3562 5.33366 14.6673 5.46699 14.934 5.73366C15.2007 6.00033 15.334 6.31144 15.334 6.66699V8.00033C15.334 8.0781 15.3257 8.16144 15.309 8.25033C15.2923 8.33921 15.2673 8.42255 15.234 8.50033L13.234 13.2003C13.134 13.4225 12.9673 13.6114 12.734 13.767C12.5007 13.9225 12.2562 14.0003 12.0007 14.0003ZM4.00065 5.33366V14.0003H1.33398V5.33366H4.00065Z" fill="#182159"/>
            </g>
            </svg>
    </button>
    <button type="button" aria-label="Thumb down" (click)="choiceMadeValue === '' && setReaction('thumbs-down')" [attr.aria-pressed]="reactionValue === 'thumbs-down'" [attr.aria-disaled]="choiceMadeValue !== ''">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_526_1746" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
            <rect width="16" height="16" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_526_1746)">
            <path d="M3.99935 2H10.666V10.6667L5.99935 15.3333L5.16602 14.5C5.08824 14.4222 5.02435 14.3167 4.97435 14.1833C4.92435 14.05 4.89935 13.9222 4.89935 13.8V13.5667L5.63268 10.6667H1.99935C1.64379 10.6667 1.33268 10.5333 1.06602 10.2667C0.799349 10 0.666016 9.68889 0.666016 9.33333V8C0.666016 7.92222 0.674349 7.83889 0.691016 7.75C0.707682 7.66111 0.732682 7.57778 0.766016 7.5L2.76602 2.8C2.86602 2.57778 3.03268 2.38889 3.26602 2.23333C3.49935 2.07778 3.74379 2 3.99935 2ZM11.9993 10.6667V2H14.666V10.6667H11.9993Z" fill="#182159"/>
            </g>
            </svg>
    </button>
</div>
@if (reactionValue) {
    <div class="reaction-info">
        @for (message of reactionDescriptions(); track $index) {
            <button
                [attr.aria-disabled]="choiceMadeValue !== ''"
                [attr.aria-pressed]="choiceMadeValue === message"
                class="btn opt-chip"
                (click)="choiceMadeValue === '' && emitChoice(message)"
                type="button"
            >{{message}}</button>
        }
    </div>
}
