import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'mon-bot-loading-spinner',
    standalone: true,
    imports: [],
    templateUrl: './bot-loading-spinner.component.html',
    styleUrl: './bot-loading-spinner.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotLoadingSpinnerComponent {
    message = input('');
}
