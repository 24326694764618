import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BotViewPoliciesType, PolicyBotFeedbackPayloadType } from '@client/app/modules/chat-bot/types/policy.type';
import { ApiService } from '@client/app/services/api/api-service/api.service';

@Injectable({
    providedIn: 'root',
})
export class ChatBotPolicyRepoService {
    constructor (
        private apiService: ApiService,
    ) {}

    createChat (): Observable<{ id: number }> {
        return this.apiService.postObservable(
            'policies/bot',
        );
    }

    addMessage (chatId: number, message: string): Observable<BotViewPoliciesType> {
        return this.apiService.postObservable(
            `policies/bot/${chatId}/chat`,
            {
                message,
            },
        );
    }

    addFeedback (chatId: number, payload: PolicyBotFeedbackPayloadType): Observable<void> {
        return this.apiService.postObservable(
            `policies/bot/${chatId}/feedback`,
            payload,
        );
    }
}
