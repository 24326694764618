import { Injectable, Pipe, PipeTransform } from '@angular/core';

/** Transform a relative url into a an absoulte url */
@Pipe({ name: 'fullUrl' })
@Injectable({ providedIn: 'root' })
export class FullUrlPipe implements PipeTransform {

    transform (value: string = '', domainUrl?: string, pageUrl?: string): string {
        let url = value.trim();

        if (domainUrl == null || url[0] !== '/') {
            const regExp = new RegExp('://');
            if (regExp.test(url)) {
                return url;
            } else if (pageUrl == null) {
                return url;
            } else {
                const urlBase = pageUrl.substring(0, pageUrl.lastIndexOf('/'));
                return `${urlBase}/${url}`;
            }
        }

        const hostRegex = new RegExp('^(https?://)([^/?#?]+)(?:[/?#]|$)', 'i');
        const matches = domainUrl.match(hostRegex);

        if (matches && matches.length >= 3) {
            url = matches[1] + matches[2] + url;
        }

        return url;
    }

}
