import { DomainInterface, PageAssistV2Type, Settings } from '../interfaces/domain.interface';
import moment, { Moment } from 'moment';
import { cloneDeep } from 'lodash';
import { PAGE_ASSIST_SCRIPT_CONSTANT } from 'app/forms/domain-script-guide/features/pa-script-settings/pa-script.constant';
import { WIDGET_SETTINGS } from 'app/core/constants/widget-settings';
import { PAGE_CORRECT_DOMAIN_SETTINGS_MODULE } from '@monsido/ng2/modules/page-fix/constants/domain-settings-modules.constant';


export const domainModelUtils = {

    weekDayOptions: ['any', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],


    getCurrentScanDay: (domain: DomainInterface, time: string, day: string, dayOffset = 0): string => {
        if (domain.scan.day !== 'any' && domain.scan.day !== null) {
            return domainModelUtils.getDayFromList(time ,day, dayOffset);
        }
        return day || 'any';
    },

    prepareSettings: (domain: DomainInterface): Settings => {
        let settings: Settings;
        if (domain.settings) {
            settings = cloneDeep(domain.settings);
        } else {
            settings = {
                scripts: {
                    page_assist: {
                        enabled: false,
                        theme: null,
                        direction: null,
                        skip_to: null,
                        manual_startup: false,
                    },
                    page_assist_v2: {
                        enabled: false,
                        theme: null,
                        direction: null,
                        language: null,
                        included_languages: [],
                        greeting: null,
                        title: null,
                        title_text: null,
                        mainColor: null,
                        text_color: null,
                        icon_picture_url: null,
                        icon_shape: null,
                        logo_picture_url: null,
                        logo_picture_base64: null,
                        skip_to: null,
                    },
                    page_correct: {
                        enabled: false,
                        admin_only: false,
                        enabled_checks: [],
                    },
                },
                data_protection: {
                    enabled_regulations: [],
                    custom_ruleset: {
                        extras: [],
                        exclusions: [],
                    },
                },
            };
        }

        if (settings.scripts) {
            const paV2Settings: Partial<PageAssistV2Type> = settings.scripts.page_assist_v2 ?? {};

            if (typeof paV2Settings.enabled !== 'boolean') {
                paV2Settings.enabled = !!settings.scripts.page_assist?.enabled;
            }

            if (!paV2Settings.theme) {
                paV2Settings.theme = PAGE_ASSIST_SCRIPT_CONSTANT.THEME.LIGHT;
            }

            if (!paV2Settings.direction) {
                paV2Settings.direction = PAGE_ASSIST_SCRIPT_CONSTANT.DIRECTION.BOTTOMLEFT;
            }

            if (typeof paV2Settings.language !== 'string') {
                paV2Settings.language = '';
            }

            if (!Array.isArray(paV2Settings.included_languages)) {
                paV2Settings.included_languages = [];
            }

            if (!paV2Settings.greeting || typeof paV2Settings.greeting !== 'string') {
                paV2Settings.greeting = 'Discover your personalization options';
            }

            if (!paV2Settings.title || typeof paV2Settings.title !== 'string') {
                paV2Settings.title = 'Personalization Options';
            }

            if (
                !paV2Settings.title_text ||
                typeof paV2Settings.title_text !== 'string'
            ) {
                paV2Settings.title_text =
                    'Welcome to PageAssist™ toolbar! Adjust the options below to cater the website to your accessibility needs.';
            }

            if (typeof paV2Settings.mainColor !== 'string') {
                paV2Settings.mainColor = '';
            }

            if (typeof paV2Settings.text_color !== 'string') {
                paV2Settings.text_color = '';
            }

            if (typeof paV2Settings.icon_picture_url !== 'string') {
                paV2Settings.icon_picture_url = PAGE_ASSIST_SCRIPT_CONSTANT.ICON.DEFAULT;
            }

            if (
                !paV2Settings.icon_shape ||
                typeof paV2Settings.icon_shape !== 'string'
            ) {
                paV2Settings.icon_shape = WIDGET_SETTINGS.ICON_SHAPE.CIRCLE;
            }

            if (typeof paV2Settings.logo_picture_url !== 'string') {
                paV2Settings.logo_picture_url = '';
            }

            if (typeof paV2Settings.logo_picture_base64 !== 'string') {
                paV2Settings.logo_picture_base64 = '';
            }

            if (typeof paV2Settings.skip_to !== 'boolean') {
                paV2Settings.skip_to = false;
            }

            if (typeof paV2Settings.enabled !== 'boolean') {
                paV2Settings.enabled = false;
            }

            settings.scripts.page_assist_v2 = paV2Settings as PageAssistV2Type;

            if (settings.scripts.page_correct && (!settings.scripts.page_correct.enabled_checks || settings.scripts.page_correct.enabled_checks.length === 0)) {
                settings.scripts.page_correct.enabled_checks = [
                    PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.SPELLING_ERROR,
                    PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_SOURCE_CODE,
                    PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK,
                    PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.LINK,
                    PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.CUSTOM,
                ];
            }
        }
        return settings;

    },

    /**
     *  Return scan day + time in UTC to submit to API request
     */
    getScanDayTimeUTC: (domain: DomainInterface, utcOffset: number): { day: string, time: string } => {
        const selectedTime = moment(domain.scan.time, 'HH:mm:ss');
        const utcTime = moment(selectedTime).subtract(utcOffset, 'minutes');
        // Converting day from current timezone to UTC for submitting to server: Shift one day backward if there is day difference
        const day = domainModelUtils.getScanDayUTC(domain, domainModelUtils.calculateDayDiffBetweenTimes(selectedTime, utcTime));

        return {
            day,
            time: domainModelUtils.getScanTimeUTC(domain, utcOffset),
        };
    },

    getScanDayUTC: (domain: DomainInterface, dayOffset = 0): string => {
        if (domain.scan.day !== 'any') {
            return domainModelUtils.getDayFromList(domain.scan.time, domain.scan.day, dayOffset);
        }
        return domain.scan.day;
    },

    getScanTimeUTC: (domain: DomainInterface, utcOffset: number): string => {
        return moment(domain.scan.time, 'HH:mm:ss').subtract(utcOffset, 'minutes')
            .format('HH:mm:ss');
    },

    getDayFromList: (time: string, day: string, dayOffset = 0): string => {
        let weekDayNo = moment(time, 'HH:mm:ss')
            .locale('en')
            .utcOffset(0) // it is crucial to set offset before day
            .day(day)
            .isoWeekday();

        if (dayOffset !== 0) {
            weekDayNo = weekDayNo - dayOffset;
            if (weekDayNo < 1) {
                weekDayNo = domainModelUtils.weekDayOptions.length - 1;
            }
            if (weekDayNo > domainModelUtils.weekDayOptions.length - 1) {
                weekDayNo = 1;
            }
        }
        return domainModelUtils.weekDayOptions[weekDayNo];
    },

    calculateDayDiffBetweenTimes: (a: Moment, b: Moment): number => {
        return a.clone().startOf('day')
            .diff(b.clone().startOf('day'), 'day');
    },

};
