export type ActionMenuItemType = {
    label: string,
    leftIcon?: string,
    spinLeftIcon?: boolean,
    rightIcon?: string,
    spinRightIcon?: boolean,
    customIcon?: string,
    disabled?: boolean,
    divider?: boolean,
    tooltip?: string,
    menuItemButtonIcons?: Array<{ monIconDirect?: string, size?: SizeProp, icon?: string[] }>,
    action?: (...args: unknown[]) => unknown,
    dataTestAttribute?: string,
};

export enum NavigationKeysEnum {
    Escape = 'Escape',
    Tab = 'Tab',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
}

type SizeProp =
  | 'xs'
  | 'lg'
  | 'sm'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';
