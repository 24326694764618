import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { BotSuggestionEntryType } from '@client/app/modules/chat-bot/components/bot-suggestions/bot-suggestions.type';


@Component({
    selector: 'mon-bot-suggestions',
    standalone: true,
    templateUrl: './bot-suggestions.component.html',
    styleUrl: './bot-suggestions.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BotSuggestionsComponent {
    @Input() suggestions: BotSuggestionEntryType[] = [
        {
            title: 'SSL certificate check',
            premable: 'Verify that all pages are served over HTTPS',
            prompt: 'I would like to find all pages on my website that are not served over HTTPS',
        },
        {
            title: 'Non-optimized images',
            premable: 'Find images that don\'t have a format optimized for web',
            prompt: 'I would like to find all images in non-optimized formats on my website',
        },
        {
            title: 'Social media readiness',
            premable: 'Identify all pages that don\'t have Open Graph markup',
            prompt: 'I would like to find all my pages that don\'t have Open Graph meta tags',
        },
    ];

    @Output() select: EventEmitter<BotSuggestionEntryType> = new EventEmitter();

}
