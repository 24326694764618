import { Component, Input, OnInit } from '@angular/core';
import { ActiveDialog } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { DialogOptionsAdapter } from '@monsido/core/modules/dialog-container/dialog-container.component';
import { Domain, DomainType } from '@monsido/modules/models/api/domain';
import { DomainGroup } from '@monsido/modules/models/api/domain-group';
import { ReportWithTemplateId, Target } from '@monsido/modules/models/api/interfaces/report.interface';
import { Report } from '@monsido/modules/models/api/report';
import { User } from '@monsido/modules/models/api/user';
import { FormScheduleReportService } from '@monsido/modules/report-center/services/form-schedule-report/form-schedule-report.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { ScheduleReportAdminUsersComponent } from '../schedule-report-admin-users/schedule-report-admin-users.component';
import { DomainRepoService } from 'app/services/api/domain-repo/domain-repo.service';
import { MonTableCollection } from '@client/ng2/models/table-collection.interface';
import { ReportCenterService } from '@client/app/blocks/service/report-center/report-center.service';
import { ReportService } from '@client/app/services/entities/report/report.service';

@Component({
    selector: 'mon-schedule-report-admin',
    templateUrl: './schedule-report-admin.component.html',
    styleUrls: ['./schedule-report-admin.component.scss'],
})
export class ScheduleReportAdminComponent implements OnInit {
    @Input() report: Report | null = null;

    maxIcons: number = 0;
    saving = false;
    loading = false;
    domains: MonTableCollection<Domain> = [];
    users: User[] = [];
    domainType = DomainType;

    domainsPage = 1;
    domainsPageSize = 10;
    domainSearchTerm = '';

    constructor (
        private eventsService: MonEventService,
        private scheduleReportService: FormScheduleReportService,
        private activeDialog: ActiveDialog,
        private domainRepo: DomainRepoService,
        private reportCenterService: ReportCenterService,
        private reportService: ReportService,
    ) {}

    async ngOnInit (): Promise<void> {
        this.maxIcons = 10;
        this.users = this.setupExistingUsers();
        await this.getDomains();
    }

    async onDomainSearch (searchTerm: string): Promise<void> {
        this.domainsPage = 1;
        this.domainSearchTerm = searchTerm;
        await this.getDomains();
    }

    async onDomainsPageChange (page: number): Promise<void> {
        this.domainsPage = page;
        await this.getDomains();
    }

    async onDomainsPageSizeChange (pageSize: number): Promise<void> {
        this.domainsPageSize = pageSize;
        this.domainsPage = 1;
        await this.getDomains();
    }

    async getDomains (): Promise<void> {
        if (!this.report) {
            throw new Error('No report');
        }
        this.loading = true;
        const domainsCollection = await this.domainRepo.getAll({
            page: this.domainsPage,
            page_size: this.domainsPageSize,
            search: this.domainSearchTerm,
        });

        const filteredDomains = this.reportCenterService.filterDomainsByReport(this.report, domainsCollection);
        this.domains = this.scheduleReportService.setupDomainUsers(filteredDomains, this.report);
        this.domains.total = domainsCollection.total;
        this.domains.currentPage = domainsCollection.currentPage;
        this.domains.perPage = domainsCollection.perPage;
        this.loading = false;
    }

    setupExistingUsers (): User[] {
        const flattenedUsers = this.report?.targets.flatMap(target => target.users);
        return [...new Map((flattenedUsers || []).map(user => [(user as User).id, user])).values()] as User[];
    }


    sendReport (): void {
        if (!this.report) {
            return;
        }
        this.saving = true;
        const params = {
            targets: this.report.targets,
        };

        params.targets = params.targets.map(target => {
            const users: Partial<User>[] =
                (target.users || []).map(function (user) {
                    if (user?.user?.id) {
                        // Internal user
                        return { user_id: user.user.id };
                    } else {
                        // External user
                        return { email: user.email, first_name: user.first_name, last_name: user.last_name };
                    }
                });

            target.users = users as User[];
            return target;
        });

        if (!this.report.id) {
            this.createReport(params);
        } else {
            this.updateReport(params);
        }
    }

    targetByTitle (index: number, target: Target): number {
        return target.id;
    }

    domainGroupById (index: number, domainGroup: DomainGroup): number {
        return domainGroup.id;
    }

    userByNameEmail (index: number, user: User): string {
        return user.first_name + user.last_name + user.email;
    }

    openUsersDialog (event: Event, domain: Domain | DomainGroup, type: DomainType, domainId?: number): void {
        if (!this.report) {
            return;
        }
        event.preventDefault();
        let targetIndex = this.report.targets.findIndex(target => {
            return domain.id === target.id && type === target.type;
        });

        if (targetIndex === -1) {
            // If target doesn't exist, add a new one
            this.report.targets.push({ id: domain.id, title: domain.title, type, users: [] });
            targetIndex = this.report.targets.length - 1;
        }

        const callback = (result: { users: User[] }): void => {
            // Set new users list
            if (this.report && result?.users) {
                domain.users = result.users;
                this.report.targets[targetIndex].users = result.users;
                this.users = this.setupExistingUsers();
            }
        };

        const params: DialogOptionsAdapter<ScheduleReportAdminUsersComponent> = {
            component: ScheduleReportAdminUsersComponent,
            dialogOptions: {
                size: 'md',
                cb: callback,
            },
            data: {
                users: domain.users,
                entityId: domain.id,
                domainType: type,
                domainId: domainId,
            },
        };


        this.eventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
    }

    closeDialog (report: Report): void {
        this.activeDialog.close(report);
    }

    private createReport (params: Partial<ReportWithTemplateId>): void {
        if (!this.report) {
            return;
        }
        params.template_id = this.report.template.id;
        this.reportService.create(params)
            .then(report => {
                this.report = report;
                this.closeDialog(report);
            })
            .finally(() => this.saving = false);
    }

    private updateReport (params: Partial<ReportWithTemplateId>): void {
        if (this.report) {
            this.reportService.update(this.report.id, params)
                .then(updatedReport => this.closeDialog(updatedReport))
                .finally(() => this.saving = false);
        }
    }
}
