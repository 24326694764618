import { Injectable } from '@angular/core';
import { HttpResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { BI } from '@monsido/core/constants/general.constant';
/** Pass untouched request through to the next request handler. */
@Injectable()
export class ApiHookInterceptor implements HttpInterceptor {
    constructor (private monEventService:MonEventService) {}

    intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let requestTimestamp: number | undefined = undefined;

        if (request.url.startsWith('http') && (BI.path && !request.url.startsWith(BI.path))) {
            requestTimestamp = new Date().getTime();
        }

        return next.handle(request).pipe(
            tap((response: HttpResponse<unknown>): void => {
                if (
                    response &&
                    response instanceof HttpResponse &&
                    request.url.startsWith('http') &&
                    (BI.path && !request.url.startsWith(BI.path))
                ) {
                    const responseTimestamp = new Date().getTime();
                    if (requestTimestamp && responseTimestamp - requestTimestamp > BI.httpSlowTime) {
                        this.monEventService.run('httpSlow', {
                            responseCode: response.status,
                            requestMethod: request.method,
                            requestUrl: request.url,
                            responseTime: responseTimestamp - requestTimestamp,
                            url: window.location.href,
                        });
                    }
                }
            }),
        );
    }
}
