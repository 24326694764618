import { Injectable } from '@angular/core';
import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { PageRepo } from '@monsido/modules/endpoints/api/page.repo';
import { Page } from '@monsido/modules/page-details/models';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { IssueInterface } from 'app/modules/accessibility/interfaces/issue-interface';
import { QaPageInterface } from 'app/modules/qa/interfaces/qa-page.interface';
import { debounce } from 'lodash';

export interface ExtensionParamsInterface {
    checkId?: number,
    contentId?: number,
    issueId: number,
    page?: Page,
    pageId?: number,
    pageLinkId?: number,
    sourceCodeId?: number,
    type?: string,
    word?: string,
    issueAbbr?: string,
    data?: {
        check?: IssueInterface
        link_id?: number
        page_id?: number
        id?: number
        word?: string
        error?: {
            id: number
        }
    },
    callback?: () => void
}
@Injectable({
    providedIn: 'root',
})
export class IssueService {
    openInExtension :(
        page: QaPageInterface | Page, params: ExtensionParamsInterface
    ) => void;
    constructor (private eventsService: MonEventService, private pageRepo: PageRepo) {
        this.openInExtension = debounce((page, params)=>{

            const sanitizedParams: Record<string, unknown> = {
                checkId: Number(params.checkId),
                contentId: Number(params.contentId),
                issueId: Number(params.issueId),
                page: page,
                data: params.data,
                pageId: Number(params.pageId),
                pageLinkId: Number(params.pageLinkId),
                sourceCodeId: Number(params.sourceCodeId),
                type: params.type,
                word: params.word,
                issueAbbr: params.issueAbbr,
            };

            Object.keys(sanitizedParams).forEach(k => {
                const value = sanitizedParams[k];
                if (value == null || value === '' || (typeof value === 'number' && isNaN(value as number))) {
                    delete sanitizedParams[k];
                }
            });

            this.eventsService.run(MON_EVENTS.OPEN_EXTENSION_DEEPLINK, sanitizedParams);
        }, 500);
    // This delay grants that the extension will open & block the user from executing the method multiple times by multiple enter presses.
    }

    searchWordInGoogle (word: string): Window | null {
        const setURL = new URL('https://www.google.com/search?q=' + word);
        return window.open(setURL.href, '_blank');
    }


    goToPageDetails (page: QaPageInterface | Page, target: string, subTarget: string | number, isDocument: boolean, callback: () => void): void {
        const data = {
            page: page,
            target: target,
            subTarget: subTarget,
            ref: '',
        };

        const params = {
            body: isDocument ? 'documentDetails' : 'pageDetails',
            size: 'page-details',
            classes: isDocument ? 'document-details-app' : 'page-details-app',
            data: data,
        };

        this.eventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
    }

    getPage (pageId: number): Promise<Page> {
        return this.pageRepo.getPage(pageId);
    }
}
